<template>
  <div>

    <portal to="breadcrumb-right">
      <b-dropdown
        size="sm"
        text="Manage"
        right
      >
        <template #button-content>
          <feather-icon
            icon="SettingsIcon"
          />
        </template>
        <b-dropdown-item>
          <feather-icon
            icon="PlusIcon"
          />
          Add organization unit
        </b-dropdown-item>
        <b-dropdown-item>
          <feather-icon
            icon="PlusIcon"
          />
          Add role
        </b-dropdown-item>
      </b-dropdown>
    </portal>

    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <p>test</p>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <tenant-detail-role-card :tenant-id="null" />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <tenant-detail-user-card :tenant-id="null" />
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BRow, BCol, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import TenantDetailRoleCard from '@/views/shared/tenant/TenantDetailRoleCard.vue'
import TenantDetailUserCard from '@/views/shared/tenant/TenantDetailUserCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    TenantDetailRoleCard,
    TenantDetailUserCard,
  },
  data() {
    return {

    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {

    },
  },
}

</script>
